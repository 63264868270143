/* ==========
  Version 3
  Blog Banner Styles Plugin for Squarespace
  Copyright Will Myers 
========== */
/*Global Styles*/
body {
  --blog-banner-overlay:black;
  --blog-header-color:white;
  --section-background-color:white;
  --blog-glass-background:hsla(0,0%,0%,.27);
}
.tweak-wm-banner .content-collection.has-banner {
  flex-direction:column;
  padding-top:0px !important; 
}
.has-banner .wm-blog-banner{
  top:0;
  position:relative;
  width:100%;
  display:flex;
}
.has-banner .wm-blog-banner .section-background-content {
  position:relative;
}
.has-banner > .content-wrapper{
  background-color: var(--section-background-color);
  z-index:1;
}
.section-background.wm-blog-banner {
  pointer-events:unset;
}
:not(.sqs-seven) .wm-blog-banner .section-background-content {
  padding: 3vw;
  display:flex;
  padding-top: var(--wM-headerHeight, 100px);
}
@media(max-width:799px) {
  :not(.sqs-seven) .wm-blog-banner .section-background-content {
    padding: 6vw;
    padding-top: var(--wM-headerHeight, 100px);
  }
}
.wm-blog-banner .section-background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.has-banner .wm-blog-banner .section-background-content{
  z-index:1;
}
.blog-item-top-wrapper.clone {
  margin-top:50px;
}
.has-banner .wm-blog-banner .section-background-image img{
  position:absolute;
  object-fit:cover;
  height:100%;
  width:100%;
  object-position:var(--image-focal-point, 50% 50%) !important;
}
.has-banner .wm-blog-banner .section-background-image .section-background-overlay{
  background-color:var(--blog-banner-overlay, black);
  opacity:.125;
  z-index:1;
}

/*7.1 Specific CSS*/
#page .has-banner .blog-item-top-wrapper.clone .blog-meta-item, 
#page .has-banner .blog-item-top-wrapper.clone .blog-meta-item a, 
#page .has-banner .blog-item-top-wrapper.clone .blog-meta-item span, 
#page .has-banner .blog-item-top-wrapper.clone .blog-item-title h1.entry-title,
#page .has-banner .blog-item-top-wrapper.clone .excerpt *{
  color: var(--blog-title-color, var(--blog-header-color, black));
}
#page .has-banner .blog-item-top-wrapper.clone .excerpt {
  order: 3;
  position:relative;
}

body.tweak-wm-banner.tweak-transparent-header:not(.header--menu-open) #header .header-nav-wrapper .header-nav-item > a, 
body.tweak-wm-banner.tweak-transparent-header:not(.header--menu-open) #header .icon-cart-quantity {
  color: var(--blog-title-color, var(--blog-header-color, black));
}
body.tweak-wm-banner.tweak-transparent-header:not(.header--menu-open) #header .header-actions .btn {
  color: var(--blog-banner-overlay);
  background-color: var(--blog-title-color, var(--blog-header-color, black));
  border-color: var(--blog-title-color, var(--blog-header-color, black));
}
body.tweak-wm-banner.tweak-transparent-header:not(.header--menu-open) #header .header-actions svg{
  fill: var(--blog-title-color, var(--blog-header-color, black));
  stroke: var(--blog-title-color, var(--blog-header-color, black));
}
/*7.0 Specific CSS*/
body.sqs-seven {
  --blog-banner-overlay:black;
  --blog-header-color:white;
  --section-background-color:white;
  --blog-glass-background:hsla(0,0%,0%,.27);
}
.sqs-seven .wm-blog-banner{
  display:flex;
  align-items: center;
  justify-content:center;
}
.sqs-seven .section-background-overlay{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
}
.sqs-seven .wm-blog-banner .blog-item-wrapper{ 
  z-index:1;
}
main.container{
  position:relative;
}
.sqs-seven .wm-blog-banner .BlogItem-title, .sqs-seven .wm-blog-banner .BlogItem-share svg, .sqs-seven .wm-blog-banner .Blog-meta-item{
  color:var(--blog-title-color, var(--blog-header-color, black));
  fill:var(--blog-title-color, var(--blog-header-color, black));
}


/* ===== STYLE 1 ===== */
.wm-banner-style-1 .wm-blog-banner{
  min-height:100vh;
}
.wm-banner-style-1 .has-banner .wm-blog-banner .section-background-image .section-background-overlay{
  background-image:linear-gradient(
    to top,
    var(--blog-banner-overlay) 0% ,
    transparent 50% 
  );
  background-color:unset;
}
.wm-banner-style-1 .has-banner .wm-blog-banner .section-background-content {
  align-items:flex-end;
  justify-content:center;
}
.wm-banner-style-1 .blog-item-top-wrapper:not(.clone) {
  display:none;
}
@media(min-width:769px){
  .wm-banner-style-1 .has-banner .blog-item-top-wrapper {
    width: var(--section-content-width, 75%);
    margin: 0 auto;
  }
}
/*Style 1 - 7.0*/
.sqs-seven.wm-banner-style-1 .wm-blog-banner{
  min-height:85vh;
  align-items: flex-end;
}
.sqs-seven.wm-banner-style-1 .blog-item-top-wrapper.clone {
  display:flex;
  flex-direction:column;
  z-index:9;
}
.wm-banner-style-1 .Main-content .BlogItem-title, 
.wm-banner-style-1 .Main-content .BlogItem-meta, 
.wm-banner-style-1 .Main-content .BlogItem-share{
  display:none;
}



/* ===== STYLE 2 ===== */
.wm-banner-style-2 .wm-blog-banner{
  min-height:60vh;
}
.wm-banner-style-2 .blog-item-top-wrapper.clone {
  display:none;
}
/*Events*/
.wm-banner-style-2 .eventitem-column-meta.clone  {
  display:none;
}



/* ===== STYLE 3 ===== */
.wm-banner-style-3 #header{
  --blog-banner-overlay:white;
  --blog-header-color:black;
}
.wm-banner-style-3 .wm-blog-banner {
  min-height:60vh;
}
.wm-banner-style-3 .has-banner .content-wrapper  .blog-item-top-wrapper {
  display:none;
}
.wm-banner-style-3 .wm-blog-banner .section-background-content{
  align-items: center;
  justify-content: center;
}
.wm-banner-style-3 .Main-content .BlogItem-title, 
.wm-banner-style-3 .Main-content .BlogItem-meta, 
.wm-banner-style-3 .Main-content .BlogItem-share{
  display:none;
}
/* 7.0 */
.sqs-seven.wm-banner-style-3 .wm-blog-banner .BlogItem-title, 
.sqs-seven.wm-banner-style-3 .wm-blog-banner .BlogItem-share svg, 
.sqs-seven.wm-banner-style-3 .wm-blog-banner .Blog-meta-item{
  color:var(--blog-header-color);
  fill:var(--blog-header-color);
}
@media(min-width:767px){
  .wm-banner-style-3 .page-section.has-banner{
    flex-direction:row;
  }
  .wm-banner-style-3 .wm-blog-banner, 
  .wm-banner-style-3 .has-banner.page-section>.content-wrapper {
    width:50%;
  }
  .wm-banner-style-3 .has-banner.page-section>.content-wrapper {
    padding-top: var(--wM-headerHeight);
    padding-bottom: calc(8vw - 1px);
  }
  .wm-banner-style-3 .has-banner .content-wrapper .blog-item-wrapper .blog-item-inner-wrapper{
    width: 100%;
  }
  .wm-banner-style-3 .wm-blog-banner {
    height: 100vh;
    position: sticky;
  }
  .wm-banner-style-3 main.container .item-pagination {
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    background:transparent;
    background-color:transparent;
    z-index: 1;
  }


  /*Style 3 - 7.0*/
  .sqs-seven.wm-banner-style-3 .Main--blog-item.has-banner {
    display:flex;
  }
  .sqs-seven.wm-banner-style-3 .wm-blog-banner {
    width:50%;
    height:100vh;
    position:sticky;
  }
  .sqs-seven.wm-banner-style-3 .has-banner .Main-content {
    width:50%;
  }
  .sqs-seven.wm-banner-style-3 .sqs-block{
    width:unset !important;
  }
}


/* ===== STYLE 4 ===== */
.wm-banner-style-3 #header{
  --blog-banner-overlay:white;
  --blog-header-color:black;
}
.wm-banner-style-4 .wm-blog-banner {
  min-height:60vh;
}
.wm-banner-style-4 .blog-item-top-wrapper.clone {
  display:none;
}
.wm-banner-style-4 .wm-blog-banner .section-background-content{
  align-items: center;
  justify-content: center;
}
@media(min-width:767px){
  .wm-banner-style-4 .page-section.has-banner{
    flex-direction:row;
  }
  .wm-banner-style-4 .wm-blog-banner, 
  .wm-banner-style-4 .has-banner.page-section>.content-wrapper {
    width:50%;
  }
  .wm-banner-style-4 .has-banner.page-section>.content-wrapper {
    padding-top: var(--wM-headerHeight);
    padding-bottom: 8vw;
  }
  .wm-banner-style-4 .has-banner .content-wrapper .blog-item-wrapper .blog-item-inner-wrapper{
    width: 100%;
  }
  .wm-banner-style-4 .wm-blog-banner {
    height: 100vh;
    position: sticky;
  }
  .wm-banner-style-4 main.container .item-pagination {
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    background:transparent;
    background-color:transparent;
    z-index: 1;
  }

  .sqs-seven.wm-banner-style-4 .Main--blog-item.has-banner {
    display:flex;
  }
  .sqs-seven.wm-banner-style-4 .wm-blog-banner {
    width:50%;
    height:100vh;
    position:sticky;
  }
  .sqs-seven.wm-banner-style-4 .has-banner .Main-content {
    width:50%;
  }
  .sqs-seven.wm-banner-style-4 .sqs-block{
    width:unset !important;
  }
}

/* ===== STYLE 5 ===== */
.wm-banner-style-5 .wm-blog-banner{
  min-height:75vh;
}
.wm-banner-style-5 .wm-blog-banner .section-background-image{
  clip-path: polygon(0 0, 0 95%, 100% calc(100% - 20vh), 100% 0%);
}
.wm-banner-style-5 .blog-item-top-wrapper.clone {
  display:none;
}
.wm-banner-style-5 .has-banner .content-wrapper .blog-item-inner-wrapper{
  padding: 4vw;
  width: var(--section-content-width, 50%);
  margin-top: -30vh;
  background-color:var(--section-background-color, black);
  box-shadow: 0px 5px 15px hsl(0deg 0% 0% / 17%);
}

@media(max-width:767px) {
  .wm-banner-style-5 .has-banner .content-wrapper .blog-item-inner-wrapper{
    padding-top:8vw;
  }
}
/*Style 5 - 7.0*/
.sqs-seven.wm-banner-style-5 article.BlogItem {
  padding-top:8vw;
  margin-top: -30vh;
  background-color:var(--section-background-color, white);
  box-shadow: 0px 5px 15px hsl(0deg 0% 0% / 17%);
}
.sqs-seven.wm-banner-style-5 .Main-content {
  position:relative
}
.sqs-seven.wm-banner-style-5 .BlogItem-pagination{
  padding-top:17px;
  padding-bottom:17px;
}
@media(max-width:767px) {
  .sqs-seven.wm-banner-style-5 article.BlogItem {
    padding: 17px;
  }
}


/* ===== STYLE 6 ===== */
.wm-banner-style-6 .wm-blog-banner{
  min-height:100vh;
}
.wm-banner-style-6 .has-banner .section-background-content {
  align-items:center;
  justify-content:center;
}
.wm-banner-style-6 .blog-item-top-wrapper.clone{
  position:relative;
  min-width: clamp(150px, 35vw, 750px);
  padding: 4vh;
}
.wm-banner-style-6 .blog-item-top-wrapper:not(.clone) {
  display:none;
}
.wm-banner-style-6 .blog-item-top-wrapper.clone:before {
  z-index: 0;
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  border-radius:3px;
  box-shadow: 0px 0px 15px hsl(0deg 0% 0% / 17%);
}
.wm-banner-style-6 .blog-item-title, .wm-banner-style-6 .blog-item-meta-wrapper {
  position:relative;
}
/*Style 6 - 7.0*/
.sqs-seven.wm-banner-style-6 .wm-blog-banner{
  min-height:85vh;
  align-items: center;
}
.sqs-seven.wm-banner-style-6 .blog-item-top-wrapper.clone {
  display:flex;
  flex-direction:column;
  z-index:9;
}
.sqs-seven.wm-banner-style-6 .wm-blog-banner .BlogItem-title, .sqs-seven.wm-banner-style-6 .wm-blog-banner .BlogItem-share svg, .sqs-seven.wm-banner-style-6 .wm-blog-banner .Blog-meta-item{
  position:relative;
  color:var(--blog-header-color);
  fill:var(--blog-header-color);
}
.wm-banner-style-6 .Main-content .BlogItem-title, .wm-banner-style-6 .Main-content .BlogItem-meta, .wm-banner-style-6 .Main-content .BlogItem-share{
  display:none;
}
.wm-banner-style-6 .blog-item-top-wrapper.clone:before {
  background-color: var(--blog-glass-background)
}


/* Updates for new SS Border Changes */
.wm-banner-style-1:not(.sqs-edit-mode-active) .page-section.has-banner > .section-border,
.wm-banner-style-2:not(.sqs-edit-mode-active) .page-section.has-banner > .section-border,
.wm-banner-style-1:not(.sqs-edit-mode-active) .page-section.has-banner > .section-divider,
.wm-banner-style-2:not(.sqs-edit-mode-active) .page-section.has-banner > .section-divider{
  position: relative;
  top: 0;
  width: 100%;
  display: flex;
}
.wm-banner-style-3:not(.sqs-edit-mode-active) .page-section.has-banner > .section-border,
.wm-banner-style-4:not(.sqs-edit-mode-active) .page-section.has-banner > .section-border, 
.wm-banner-style-3:not(.sqs-edit-mode-active) .page-section.has-banner > .section-divider,
.wm-banner-style-4:not(.sqs-edit-mode-active) .page-section.has-banner > .section-divider{
  width: 50%;
  min-height: 100vh;
  position: sticky;
}

.wm-banner-style-3:not(.sqs-edit-mode-active) .has-banner .wm-blog-banner, 
.wm-banner-style-4:not(.sqs-edit-mode-active) .has-banner .wm-blog-banner{
  width:100%;
} 
@media(max-width:768px) {
  .wm-banner-style-3:not(.sqs-edit-mode-active) .page-section.has-banner > .section-border,
  .wm-banner-style-4:not(.sqs-edit-mode-active) .page-section.has-banner > .section-border,
  .wm-banner-style-3:not(.sqs-edit-mode-active) .page-section.has-banner > .section-divider,
  .wm-banner-style-4:not(.sqs-edit-mode-active) .page-section.has-banner > .section-divider{
    top: 0;
    position: relative;
    width: 100%;
    display: flex;
    min-height: 60vh;
  }
}

.wm-banner-style-6:not(.sqs-edit-mode-active) .page-section.has-banner > .section-border,
.wm-banner-style-5:not(.sqs-edit-mode-active) .page-section.has-banner > .section-border, 
.wm-banner-style-6:not(.sqs-edit-mode-active) .page-section.has-banner > .section-divider,
.wm-banner-style-5:not(.sqs-edit-mode-active) .page-section.has-banner > .section-divider{
  top: 0;
  position: relative;
  width: 100%;
  display: flex;
}
